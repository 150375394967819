import { Component } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../core/services/data.service';
import { UtilityService } from '../../core/services/utils.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NgbDropdownModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  constructor(public dataService: DataService,
    private authService: AuthService,
    private userService: UserService,
    private utilityService: UtilityService){}
  onLogout(): void{
    this.authService.logout().subscribe(res=>{
      this.dataService.clearLocalStorage();
      this.utilityService.onNavigate('/home');
    })
  }
  onChangeProfilePic(event: any): void{
    event.stopPropagation()
    if(event.target && event.target.files && event.target.files[0]){
      const userId = this.dataService.getLocalStorageItem('user_id') || '';
      const formData = new FormData();
      formData.append('profilePic', event.target.files[0])
      this.userService.updateProfilePic(userId, formData).subscribe(res=>{
        this.dataService.setLocalStorageItem('profile_pic', res.response.profile_pic)
      })
    }
  }
}
