<div class="position-fixed top-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col text-end">
        <div ngbDropdown class="d-inline-block bg-white py-2">
          <button type="button" class="border-0 bg-transparent d-flex align-items-center" id="dropdownBasic1"
            ngbDropdownToggle>
            <label for="profile-pic" class="pointer" (change)="onChangeProfilePic($event)">
              <input type="file" accept="image/*" id="profile-pic" class="d-none">
              <img
                [src]="dataService.getLocalStorageItem('profile_pic') ? dataService.getLocalStorageItem('profile_pic') :'../../../assets/images/user.png'"
                alt="User" class="user" />
            </label>

            <div class="ps-2 text-start">
              <label class="font-11 mb-0">Logged in as Auditor</label>
              <h5 class="fw-bold font-16">{{dataService.getLocalStorageItem('user_name')}}</h5>
            </div>
          </button>
          <div ngbDropdownMenu class="border-0" aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem (click)="onLogout()">Logout</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
