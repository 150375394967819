import { MessageService } from 'primeng/api';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomMessageService } from '../services/custom-message.service';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-message',
  standalone: true,
  imports: [ToastModule],
  providers: [MessageService],
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {
  subscription!: Subscription;
  customSuccess! :boolean;
  constructor(private messagingService: CustomMessageService , private messageService: MessageService) { }
  ngOnInit() {
    this.subscribeToNotifications();
  }

  subscribeToNotifications() {
    this.subscription = this.messagingService.notificationChange
    .subscribe((notification:any) => {
      this.customSuccess = notification.customSuccess
      this.messageService.clear();
      notification.detail ? this.messageService.add(notification) : '';
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
