import { Injectable } from '@angular/core';
import { Router, CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { DataService } from '../core/services/data.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard implements CanLoad {
  constructor(public auth: AuthService, public router: Router, private dataService: DataService) { }
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    const userType = this.dataService.getLocalStorageItem('user_type')
    if (userType != 'SUPER_ADMIN') {
      this.router.navigate(['dashboard/calender']);
      return false;
    }
    return true;

  }
}
