<app-message></app-message>
<div class="main-wrapper" [className]="isHomePage || isLoginPage ? 'full-width-wrapper' : 'main-wrapper'">
  @if(!isHomePage && !isLoginPage){

  <div class="" [className]="menuOpen ? 'menu-wrapper py-2 d-none d-md-block ': 'menu-collapse py-2 d-none d-md-block'">
    <app-menu (menuToggled)="handleToggle($event)" />
  </div>
  }
  <div [className]="menuOpen ? 'content-wrapper' :'content-wrapper-2'">
    @if(!isHomePage && !isLoginPage){
    <app-header />
    }
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
