<div>
  <div class="text-center position-relative">
    <img src="../../../assets/images/logo.png" alt="Logo" class="logo mt-3 mx-auto" />
    <img class="position-absolute" (click)="toggleMenu()" src="../../../assets/images/menu-icons/previous.png"
      alt="Previous" class="toggle-menu" loading="lazy">
  </div>
  <ul class="menu-items ps-0 w-100 py-3">
    <li [ngClass]="{'active':isActive('/dashboard')}" *ngIf="isAccessable(EMenuItems.DASHBOARD)" (click)="setActiveMenuItem('/dashboard')">
      <img src="../../../assets/images/menu-icons/dashboard-1.png" alt="Dashboard" loading="lazy">
      <span class=" ps-2">Dashboard</span>
    </li>
    <li [ngClass]="{'active': isActive('/dashboard/calender')}" *ngIf="isAccessable(EMenuItems.CALENDER)"
      (click)="setActiveMenuItem('/dashboard/calender')">
      <img src="../../../assets/images/menu-icons/calendar-2.png" alt="Calendar" loading="lazy">
      <span class=" ps-2">Calender</span>
    </li>


    <li [ngClass]="{'active': isActive('/master')}" *ngIf="isAccessable(EMenuItems.MASTER)"
      (click)="setActiveMenuItem('/master')">
      <img src="../../assets/images/menu-icons/admin-1.png" alt="Admin" loading="lazy">
      <span class=" ps-2">Admin Master</span>

    </li>
    <li class="d-block dropdown-submenu" [ngClass]="{'open':isDropdownOpen}" (click)="toggleDropdown()" *ngIf="isAccessable(EMenuItems.AUDIT)">
      <img src="../../assets/images/menu-icons/audit-1.png" alt="Audit Listing" loading="lazy">
      <span class=" ps-2">Plan Audit</span>
    </li>
    <ul class="d-block ps-0 submenu" *ngIf="isDropdownOpen">
      <li class="ps-5" (click)="setActiveMenuItem('/audit/audit-plan/create-plan')"
        [ngClass]="{'active': isActive('/audit/audit-plan/create-plan')}">
        Add
        Audit Plan</li>
      <li class="ps-5" (click)="setActiveMenuItem('/audit/audit-plan/manage-audit')"
        [ngClass]="{'active': isActive('/audit/audit-plan/manage-audit')}">View
        Audit Plan</li>

    </ul>
    <li [ngClass]="{'active': isActive('/assessment/assessment-listing')}"
      *ngIf="isAccessable(EMenuItems.ASSESSMENT_LISTING)" (click)="setActiveMenuItem('/assessment/assessment-listing')">
      <img src="../../assets/images/menu-icons/assessment-1.png" alt="Assessment" loading="lazy">
      <span class=" ps-2">Assessment</span>

    </li>
    <li [ngClass]="{'active': isActive('/review/assessment-listing')}"
      *ngIf="isAccessable(EMenuItems.REVIEW_ASSESSMENT)" (click)="setActiveMenuItem('/review/assessment-listing')">
      <img src="../../assets/images/menu-icons/review-assessment-1.png" alt="Review Assessment" loading="lazy">
      <span class=" ps-2">Review Assessment</span>
    </li>
    <li [ngClass]="{'active': isActive('/action-plan/action-listing')}" *ngIf="isAccessable(EMenuItems.ACTION_LISTING)"
      (click)="setActiveMenuItem('/action-plan/action-listing')">
      <img src="../../assets/images/menu-icons/action-listing-1.png" alt="Action Listing" loading="lazy">
      <span class=" ps-2">Action-NC Points</span>
    </li>
    <li [ngClass]="{'active': isActive('/action-plan/review-action')}" *ngIf="isAccessable(EMenuItems.ACTION_REVIEW)"
      (click)="setActiveMenuItem('/action-plan/review-action')">
      <img src="../../assets/images/menu-icons/action-review-1.png" alt="Action Review" loading="lazy">
      <span class=" ps-2">Review Submitted Actions</span>

    </li>
    <li [ngClass]="{'active': isActive('/action-plan/final-report')}" *ngIf="isAccessable(EMenuItems.FINAL_REPORT)"
      (click)="setActiveMenuItem('/action-plan/final-report')">
      <img src="../../assets/images/menu-icons/final-report-1.png" alt="Final Report" loading="lazy">
      <span class=" ps-2">Final Report</span>
    </li>
    <li [ngClass]="{'active': isActive('/audit/yearly-audit-plan')}" *ngIf="isAccessable(EMenuItems.YEARLY_CALENDER)"
      (click)="setActiveMenuItem('/audit/yearly-audit-plan')">
      <img src="../../assets/images/menu-icons/yearly-calendar-1.png" alt="Yearly Calendar" loading="lazy">
      <span class=" ps-2">Yearly Calender</span>

    </li>
    <li [ngClass]="{'active': isActive('/audit/machine-audit-plan')}" *ngIf="isAccessable(EMenuItems.MACHINE_CALENDER)"
      (click)="setActiveMenuItem('/audit/machine-audit-plan')">
      <img src="../../assets/images/menu-icons/machine-calendar-1.png" alt="Machine Calendar" loading="lazy">
      <span class=" ps-2">Machine Calender</span>
    </li>
    <li [ngClass]="{'active': isActive('/email/manage-email')}" *ngIf="isAccessable(EMenuItems.EMAIL_MANAGEMENT)"
      (click)="setActiveMenuItem('/email/manage-email')">
      <img src="../../assets/images/menu-icons/email-management-1.png" alt="Email" loading="lazy">
      <span class=" ps-2">Email Management</span>
    </li>
    <li [ngClass]="{'active': isActive('/user/user')}" *ngIf="isAccessable(EMenuItems.USER_MANAGEMENT)"
      (click)="setActiveMenuItem('/user/user')">
      <img src="../../assets/images/menu-icons/user-management-1.png" alt="User Management" loading="lazy">
      <span class="text-secondary ps-2">User Management</span>
    </li>
    <li [ngClass]="{'active': isActive('/audit/audit-plan/admin-audit')}" *ngIf="isAccessable(EMenuItems.ADMIN_AUDIT_MANAGEMENT)"
    (click)="setActiveMenuItem('/audit/audit-plan/admin-audit')">
    <img src="../../assets/images/menu-icons/admin-audit-management.png" alt="Admin Audit Management" loading="lazy">
    <span class="text-secondary ps-2">Admin Audit Management</span>
  </li>
  </ul>
</div>