import { Component } from '@angular/core';
import { MenuComponent } from './components/menu/menu.component';
import { HeaderComponent } from './components/header/header.component';
import { MessageComponent } from './core/message/message.component';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { filter } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MenuComponent, HeaderComponent, MessageComponent, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  isHomePage: boolean = false;
  isLoginPage :boolean = false;
  menuOpen:boolean  = true;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const firstChild = this.activatedRoute.firstChild;
        this.isHomePage = firstChild ? firstChild.snapshot.routeConfig?.path === '' : false;
        this.isLoginPage = firstChild ? firstChild.snapshot.routeConfig?.path === 'login' : false;
      });
  }
  handleToggle(isOpen :boolean){
    this.menuOpen = isOpen;
  }
}
