import { Injectable } from '@angular/core';
import { CanLoad, Router,  } from '@angular/router';
import { AuthService } from './auth.service';
import { DataService } from '../core/services/data.service';
@Injectable({
    providedIn: 'root'
  })
export class AuthGuard implements CanLoad {
  constructor(public auth: AuthService, public router: Router, private dataService: DataService) {}
  canLoad(): boolean {
    const isLoggedIn = this.dataService.getLocalStorageItem('access_token')
    if (!isLoggedIn) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}