export enum EMenuItems {
    DASHBOARD = 'DASHBOARD',
    CALENDER = 'CALENDER',
    MASTER = 'MASTER',
    AUDIT = 'AUDIT',
    ASSESSMENT_LISTING = 'ASSESSMENT_LISTING',
    ACTION_LISTING = 'ACTION_LISTING',
    REVIEW_ASSESSMENT = 'REVIEW_ASSESSMENT',
    ACTION_REVIEW = 'ACTION_REVIEW',
    FINAL_REPORT = 'FINAL_REPORT',
    YEARLY_CALENDER = 'YEARLY_CALENDER',
    MACHINE_CALENDER = 'MACHINE_CALENDER',
    EMAIL_MANAGEMENT = 'EMAIL_MANAGEMENT',
    USER_MANAGEMENT = 'USER_MANAGEMENT',
    ADMIN_AUDIT_MANAGEMENT = 'ADMIN_AUDIT_MANAGEMENT'
}
