import { Routes } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';
import { SuperAdminGuard } from './services/super-admin-guard.service';

export const routes: Routes = [
  {
    path: '', pathMatch: 'full',
    data: {
      breadcrumb: 'Home'
    },
    loadComponent: () => import('./components/home-page/home-page.component').then(comp => comp.HomePageComponent)
  },
  {
    path: 'home',
    redirectTo: '/',
  },
  {
    path: 'audit',
    data: {
      breadcrumb: 'Audit'
    },
    loadChildren: () => import('./modules/audit/audit.route').then(mod => mod.AUDIT_ROUTES),
    canLoad: [AuthGuard],
  },
  {
    path: 'master',
    data: {
      breadcrumb: 'Master'
    },
    loadChildren: () => import('./modules/master/master.routes').then(mod => mod.MASTER_ROUTES),
    canLoad: [AuthGuard, SuperAdminGuard],
  },
  {
    path: 'user',
    data: {
      breadcrumb: 'User'
    },
    canLoad: [AuthGuard, SuperAdminGuard],
    loadChildren: () => import('./modules/user/user.route').then(mod => mod.USER_ROUTE)
  },
  {
    path: 'email',
    data: {
      breadcrumb: 'Email'
    },
    canLoad: [AuthGuard, SuperAdminGuard],
    loadChildren: () => import('./modules/email/email.route').then(mod => mod.ROUTES)
  },
  {
    path: 'login',
    loadComponent: () => import('./components/login/login.component').then(comp => comp.LoginComponent)
  },
  {
    path: 'assessment',
    data: {
      breadcrumb: 'Assessment'
    },
    canLoad: [AuthGuard],
    loadChildren: () => import('./modules/assessment/assessment.route').then(mod => mod.ASSESSMENT_ROUTE)
  },
  {
    path: 'review',
    data: {
      breadcrumb: 'Review'
    },
    canLoad: [AuthGuard],
    loadChildren: () => import('./modules/review-assessment/review-assessment.route').then(mod => mod.ASSESSMENT_ROUTE)
  },
  {
    path: 'dashboard',
    data: {
      breadcrumb: 'Dashboard'
    },
    canLoad: [AuthGuard],
    loadChildren: () => import('./modules/dashboard/dashboard.route').then(mod => mod.ROUTES)
  },
  {
    path: 'action-plan',
    data: {
      breadcrumb: 'Action Plan'
    },
    canLoad: [AuthGuard],
    loadChildren: () => import('./modules/action-plan/action-plan.route').then(mod => mod.ROUTES)
  },
  {
    path: 'employee',
    data: {
      breadcrumb: 'employee'
    },
    canLoad: [AuthGuard, SuperAdminGuard],
    loadChildren: () => import('./modules/employee/employee.route').then(mod => mod.ROUTES)
  },
  {
    path: '**',
    redirectTo: 'dashboard/calender',
    // loadComponent: () => import('./components/page-not-found/page-not-found.component').then(comp => comp.PageNotFoundComponent)
  },
];
