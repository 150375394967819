import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UtilityService } from '../../core/services/utils.service';
import { CommonModule } from '@angular/common';
import { IService } from '../../interfaces/user.interface';
import { DataService } from '../../core/services/data.service';
import { EMenuItems } from '../../enums/menu.enum';
import { NgIf } from '@angular/common';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [CommonModule, NgIf],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements OnInit {
  @Output() menuToggled = new EventEmitter<boolean>();
  isOpen:boolean  = false;
  EMenuItems = EMenuItems;
  services: IService[] = [];
  activeMenuItem : string ='';
  isDropdownOpen:boolean = false;

  constructor(public utilService:UtilityService,
    private dataServices: DataService){
  }

  ngOnInit(): void {
    this.services = this.dataServices.getLocalStorageItem('service_ids') || []
  }

  toggleMenu(){
    this.isOpen = !this.isOpen;
    this.menuToggled.emit(this.isOpen)
  }

  setActiveMenuItem(menuItem: string):void{
    this.activeMenuItem = menuItem;
    this.utilService.onNavigate(menuItem)
  }

  isActive(menuItem:string){
    return this.activeMenuItem === menuItem;
  }
  isAccessable(menu: EMenuItems): boolean {
    const adminServices = [EMenuItems.MASTER, EMenuItems.USER_MANAGEMENT, EMenuItems.EMAIL_MANAGEMENT, EMenuItems.ADMIN_AUDIT_MANAGEMENT];
    const user = this.dataServices.getLocalStorageItem('user_type') ?? ''
    if(user === 'SUPER_ADMIN'){
        return true
    }else{
      if(adminServices.includes(menu)){
        return !!this.services.find(ele => ele.service_name === menu);
      }else{
        return true
      }
    }

  }
  toggleDropdown(){
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
